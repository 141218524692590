import React from 'react';
import styled from 'styled-components';

import { color } from '../../styles';

const ProgressWrapper = styled.div<{ active: boolean }>`
  position: relative;
  padding-left: 2.35em;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
  height: ${(p) => (p.active ? '6.15em' : '2em')};
  border-radius: 8px;
`;

const ProgressLine = styled.div<{ active: boolean }>`
  position: absolute;
  background-color: ${color.mediumGrey};
  width: 0.08em;
  height: ${(p) => (p.active ? '6.15em' : '2.5em')};
  border-radius: 8px;
`;

const ProgressStatus = styled.div<{ status: number }>`
  position: absolute;
  background-color: ${color.busy};
  width: 0.15em;
  margin-left: -0.025em;
  ${(p) => p.status && `height: ${p.status}%;`}
  border-radius: 8px;
`;

type Props = {
  active: boolean;
  free: boolean;
  status: number;
};
export default function ProgressBar({ active, free, status }: Props) {
  return (
    <ProgressWrapper active={active}>
      <ProgressLine active={active} />
      {active && !free && <ProgressStatus status={status} />}
    </ProgressWrapper>
  );
}

import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useRef, useState } from 'react';

import config from '../../config';
import { RoomsQuery } from '../../generated/RoomsQuery';
import Room from '../../models/Room';
import Timer from '../../models/Timer';
import { ROOMS_QUERY } from './queries';

export default function useRoomData(primaryId: string | null) {
  const timer = useRef(new Timer());
  const [rooms, setRooms] = useState<Room[]>([]);

  const { refetch: fetchRooms, data } = useQuery<RoomsQuery>(ROOMS_QUERY, {
    fetchPolicy: 'network-only',
    skip: !primaryId,
    pollInterval: config.FETCH_INTERVAL,
  });

  useEffect(() => {
    if (data) {
      setRooms(
        data.rooms?.map((room) => new Room(timer.current, room!, primaryId)) ??
          [],
      );
    }
  }, [data, primaryId]);

  return useMemo(
    () => ({
      rooms,
      fetchRooms,
    }),
    [rooms, fetchRooms],
  );
}

const config = Object.freeze({
  SLOT_OVERLAP_TIME: 16,
  BOOKING_SLOTS: [5, 10, 15, 30, 45],
  SIGANGE_INACTIVE_TIMEOUT: 1,
  PREINIT_INTERVAL: 1000 * 60 * 2,
  FETCH_INTERVAL: 1000 * 60 * 30,
  CHECK_NEW_RELEASE_INTERVAL: 1000 * 60 * 30,
  INTERVAL_DB_SETTINGS_UPDATE: 60 * 1000,
});

export default config;

import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import Loader from '../components/common/Loader';
import { PublicName } from '../generated/PublicName';
import { color } from '../styles';
import { useClock } from '../provider/ClockProvider';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  width: 100vw;
  transform: translateY(-60%);
`;
const Brand = styled.div`
  font-weight: 600;
  font-size: 2.5em;
  color: ${color.white};
  margin-bottom: 3em;
`;
const Name = styled.span`
  font-weight: 300;
  font-size: 1em;
  color: ${color.white};
`;
const Info = styled.span`
  font-weight: 300;
  font-size: 0.8em;
  color: ${color.white};
`;

const Login = styled.div`
  display: inline-block;
  background: #19c5ff;
  color: ${color.white};
  border: none;
  border-radius: 3px;
  padding: 0.7em 0.8em;
  font-size: 16px;
`;

const Input = styled.input<{ error: boolean }>`
  border: none;
  border-bottom: 2px solid;
  border-color: ${(p) => (p.error ? color.busy : color.white)};
  background: none;
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin: auto;
  color: ${color.white};
  padding: 1em 0.5em;
  border-radius: 0;
  margin-bottom: 5em;
  user-select: initial;
  &:focus {
    outline: none;
  }
  user-select: auto;
`;

export const REQUEST_SIGNAGE_APPROVAL = gql`
  mutation RequestSignageApproval($info: String!, $name: String!) {
    requestSignageApproval(info: { info: $info }, name: $name)
  }
`;

export const PUBLIC_NAME = gql`
  query PublicName {
    publicSettings {
      name
    }
  }
`;

type Props = {
  status: string | undefined | null;
};

export default function LoginPage({ status }: Props) {
  const { clientAuthPollInterval } = useClock();
  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  const [requestApproval] = useMutation(REQUEST_SIGNAGE_APPROVAL);
  const publicData = useQuery<PublicName>(PUBLIC_NAME, {
    fetchPolicy: 'cache-first',
  });
  const [isPending, setIsPending] = useState(false);
  useEffect(() => {
    if (status === 'pending') {
      setIsPending(true);
    }
  }, [status]);

  const handleLoginRequest = useCallback(() => {
    if (name) {
      setIsPending(true);
      requestApproval({
        variables: { info: window.navigator.userAgent, name },
      });
      setError(false);
    } else {
      setError(true);
    }
  }, [name, requestApproval]);

  return (
    <Wrapper>
      <div>
        <Brand>
          {publicData.data?.publicSettings?.name} <Name>Rooms</Name>
        </Brand>
        {isPending ? (
          <>
            <Loader />
            <Info>
              Waiting at least {clientAuthPollInterval / 1000}s for approval.
              Hang in there!
            </Info>
          </>
        ) : (
          <>
            <Input
              autoFocus
              type="text"
              placeholder="Device Name*"
              value={name}
              error={error}
              onChange={(e) => setName(e.currentTarget.value)}
            />
            <Login onClick={handleLoginRequest}>Request Approval</Login>
          </>
        )}
      </div>
    </Wrapper>
  );
}

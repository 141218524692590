import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';

import { socketConformName } from '../../utils';

export default function useSockets(
  fetchRooms: () => void,
  fetchSettings: () => void,
  connectionName?: string,
  deviceId?: string,
) {
  const socket = useRef<Socket | null>(null);

  useEffect(() => {
    // init sockets
    console.log(
      'init sockets',
      connectionName,
      deviceId,
      socket,
      !socket.current,
    );
    if (connectionName) {
      if (!socket.current) {
        socket.current = io(`/${socketConformName(connectionName)}`);
        console.log('socket.current', connectionName);
      }
      socket.current.on('connect', () => {
        if (deviceId) {
          socket.current?.emit('roomId', deviceId);
        }
      });

      socket.current.on('roomUpdate', fetchRooms);
      socket.current.on('settingsUpdate', fetchSettings);
    }

    return () => {
      if (socket.current) {
        socket.current?.on('drain', () => {
          console.log('All events sent have been received by the server');
          socket.current?.disconnect();
        });
      }
    };
  }, [connectionName, deviceId, fetchRooms, fetchSettings]);
}

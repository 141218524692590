import styled, { css } from 'styled-components';

import Room from '../models/Room';
import { color } from '../styles';

/**
 * @description CSS-Escapes all '#' inside a string
 *
 * @param {string} s
 * @returns Escaped string
 */
function cssesc(s: string): string {
  return s.replace(/([#])/g, '\\$1');
}

/**
 * @description Creates CSS styling for every room depending on its free/busy state
 * @return Object of css rules
 */
const roomStyles = (rooms: Room[]) =>
  rooms.map(
    (r) =>
      css`
        & #Rooms #${cssesc(r.nameId)} {
          stroke: ${r.isFree ? color.free : color.busy};
          fill: transparent;
          cursor: pointer;
        }
        & #Rooms #${`${cssesc(r.nameId)}-Label`} {
          fill: ${r.isFree ? color.free : color.busy};
        }
      `,
  );

type MapProps = {
  rooms: Room[];
};

export const Map = styled.div<MapProps>`
  display: flex;
  flex: 1;
  height: 100%;
  padding: 2em 0.5em;
  box-sizing: border-box;
  svg {
    height: 100%;
    max-height: 100%;
    flex: 1;
    ${(p) => roomStyles(p.rooms)}
  }
`;

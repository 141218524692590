import { useMutation } from '@apollo/client';

import {
  BookMutation_bookRoom,
  BookMutationVariables,
} from '../../generated/BookMutation';
import {
  CancelMutation,
  CancelMutationVariables,
} from '../../generated/CancelMutation';
import { InitQuery_floors } from '../../generated/InitQuery';
import Room from '../../models/Room';
import { b64Decode } from '../../utils';
import { BOOK_MUTATION, CANCEL_MUTATION, ROOMS_QUERY } from './queries';

export function prepareFloor(floor: InitQuery_floors | null) {
  if (!floor) return null;
  const { floorNumber, id, map } = floor;
  return {
    floorNumber,
    id,
    map: {
      horizontal: b64Decode(map.horizontal),
      portrait: b64Decode(map.portrait),
    },
  };
}

export function useBookRoom() {
  const [bookRoom] = useMutation<BookMutation_bookRoom, BookMutationVariables>(
    BOOK_MUTATION,
  );

  return bookRoom;
}

export function useCancelRoom() {
  const [mutation] = useMutation<CancelMutation, CancelMutationVariables>(
    CANCEL_MUTATION,
  );

  return mutation;
}

export function getRoom(rooms: Room[], roomId: string | null) {
  if (!roomId) return undefined;

  const roomData = rooms.find(
    (room) =>
      room?.id?.toUpperCase() === roomId.toUpperCase() ||
      room?.name?.toUpperCase() === roomId.toUpperCase(),
  );
  return roomData;
}

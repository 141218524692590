import { useEffect, useRef } from 'react';

export default function useInteraction(cb: () => void) {
  const interactionTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const interaction = () => {
      if (interactionTimer.current) {
        clearTimeout(interactionTimer.current);
      }
      interactionTimer.current = setTimeout(() => {
        cb();
      }, 30 * 1000);
    };
    interaction();

    window.addEventListener('pointerdown', interaction);
    return () => {
      window.removeEventListener('pointerdown', interaction);
      if (interactionTimer.current) {
        clearTimeout(interactionTimer.current);
      }
    };
  }, [cb]);
}

export function getFormatedTime(date: string | Date) {
  const d = new Date(date);
  const h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
  const m = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
  return `${h}:${m}`;
}

/**
 * @description Decodes base64 string into UTF8
 * @param  {string} str
 * @returns UTF8 encoded string
 */
export const b64Decode = (str: string) => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
};

/**
 * @description Compare function for defining a sort order
 * Objects a sorted by their name properties
 * @param  {Object} a
 * @param  {Object} b
 */
export function sortByName<T extends { name: string }>(a: T, b: T) {
  const nameA = a.name.toUpperCase(); // ignore upper and lowercase
  const nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

/**
 * @description Replaces any whitespace and special character in a string.
 * @param  {string} name
 * @returns Escapes string.
 */
export const socketConformName = (name: string): string => {
  return name.replace(/[^A-Z0-9]+/gi, '_');
};

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React from 'react';

import { useOnlineState } from './hooks/useOnline';
import useUpdate from './hooks/useUpdate';
import OfflinePage from './Pages/OfflinePage';
import ClockProvider from './provider/ClockProvider';
import RoomsProvider from './provider/RoomsProvider';
import Root from './Root';

// Initialize Apollo Client
const client = new ApolloClient({
  uri: '/api/app',
  cache: new InMemoryCache(),
});

const defaultClientAuthPollInterval =
  // TODO JOK: reset production value to 5min, after testing on stag is done! find a dynamic solution for this
  process.env.NODE_ENV === 'production' ? 1000 * 10 : 1000 * 15;

function App() {
  useUpdate();
  const isOnline = useOnlineState();

  if (!isOnline) return <OfflinePage />;

  return (
    <ApolloProvider client={client}>
      <ClockProvider
        unsafeClientAuthPollInterval={
          // ATTENTION: CLIENT_AUTH_POLL_INTERVAL won't be available,
          //            when the client is started as part of the cellular_rooms server
          Number(process.env.CLIENT_AUTH_POLL_INTERVAL) ||
          defaultClientAuthPollInterval
        }
      >
        <RoomsProvider>
          <Root />
        </RoomsProvider>
      </ClockProvider>
    </ApolloProvider>
  );
}

export default App;

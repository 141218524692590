import { gql, useQuery } from '@apollo/client';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { CheckAuth } from './generated/CheckAuth';
import LoginPage from './Pages/LoginPage';
import RoomDetailsPage from './Pages/RoomDetailsPage';
import RoomOverviewPage from './Pages/RoomOverviewPage';
import { useRooms } from './provider/RoomsProvider';
import { CHECK_AUTH } from './queries/checkAuth';
import { color } from './styles';
import { useClock } from './provider/ClockProvider';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  box-sizing: border-box;
`;

export const GlobalStyle = createGlobalStyle`
  html,
  body
  {
    margin: 0;
    background-color: ${color.background};
    font: 3.6vmin/1.2 sans-serif;
    font-family: Open Sans, sans-serif;
    box-sizing: border-box;
  }
  body {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  * {
    user-select: none;
  }
`;

export default function Root() {
  const { clientAuthPollInterval: pollInterval } = useClock();
  const { data, loading } = useQuery<CheckAuth>(CHECK_AUTH, { pollInterval });
  const { selectedRoom } = useRooms();

  if (loading) return null;
  return (
    <Container>
      <GlobalStyle />
      {data?.authStatus?.status !== 'true' ? (
        <LoginPage status={data?.authStatus?.status} />
      ) : selectedRoom ? (
        <RoomDetailsPage />
      ) : (
        <RoomOverviewPage />
      )}
    </Container>
  );
}

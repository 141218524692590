import { useEffect, useState } from 'react';

export enum Orientation {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

export default function useOrientation() {
  const [orientation, setOrientation] = useState<Orientation>(
    window.innerWidth > window.innerHeight
      ? Orientation.LANDSCAPE
      : Orientation.PORTRAIT,
  );

  useEffect(() => {
    const listener = () => {
      const { innerWidth, innerHeight } = window;
      if (innerWidth > innerHeight) {
        setOrientation(Orientation.LANDSCAPE);
      } else {
        setOrientation(Orientation.PORTRAIT);
      }
    };
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  });

  return orientation;
}

import React from 'react';
import styled, { css } from 'styled-components';

import Event from '../models/Event';
import { useRooms } from '../provider/RoomsProvider';
import { color } from '../styles';
import BookButton from './common/BookButton';
import { Button } from './common/Button';
import ProgressBar from './common/ProgressBar';

const StatusWrapper = styled.div<{ first: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: start;
  overflow: hidden;
  padding-top: 0.1em;
  ${(p) =>
    !p.first &&
    css`
      opacity: 0.6;
    `}
`;

const Time = styled.div`
  font-size: 1.9em;
  font-weight: 300;
  margin-right: 1em;
  line-height: 0.75em;
  height: 0.79em;
  color: ${color.white};
`;

const Summary = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const CancelButton = styled(Button)<{ first?: boolean }>`
  background: ${(p) => (p.first ? color.busy : color.darkGrey)};
`;

const Room = styled.div`
  display: block;
  text-decoration: none;
  max-width: 100%;
`;

const Title = styled.div<{ first: boolean; big?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  white-space: nowrap;
  max-width: 74vw;
  font-weight: 600;
  line-height: 0.7em;
  color: ${color.white};
  ${(p) => css`
    margin-bottom: ${p.first && '.2em'};
    font-size: ${p.big ? '1.9em' : '.75em'};
    height: ${p.big ? '1em' : '1.1em'};
  `}
`;

const SubTitle = styled.div<{ first?: boolean }>`
  font-weight: 300;
  color: #666;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 74vw;
  line-height: 1em;
  height: 1.2em;
  color: ${color.white};
  ${(p) => css`
    font-size: ${p.first ? '1.1em' : '.75em'};
    line-height: ${p.first ? '1.1em' : '.75em'};
    padding-top: ${p.first && '0.2em'};
  `}
`;

type Props = {
  event: Event;
  first: boolean;
  last: boolean;
};
export default function EventCard({ event, first, last }: Props) {
  const { timer } = event;
  const { cancel } = useRooms();

  const progress = () =>
    ((event.duration - event.remainingDuration) / event.duration) * 100;

  /**
   * @description cancels the event
   */
  const handleCancelClick = () => {
    cancel(event);
  };

  const hasBigTitle = first || !event.bookable || last;
  return (
    <StatusWrapper first={first}>
      <div>
        <Time>{`${event.start.format('HH:mm')}`}</Time>
        {!last && (
          <ProgressBar
            active={first}
            free={event.isFree}
            status={first ? progress() : 0}
          />
        )}
      </div>
      <Summary>
        <div>
          <Room>
            {hasBigTitle ? (
              <Title first={first} big={first}>
                {event.computedSummary}
              </Title>
            ) : null}
          </Room>
          <>
            {event.own && first ? (
              <div>
                <CancelButton first onClick={handleCancelClick}>
                  Cancel
                </CancelButton>
              </div>
            ) : event.isFree ? (
              event.bookable && (first || !last) ? (
                <BookButton quickbooking={first} event={event} />
              ) : (
                <SubTitle first={first}>For {event.humanizedDuration}</SubTitle>
              )
            ) : first || !event.organizer ? (
              <SubTitle first={first}>
                Ends in {event.end.from(timer.time, true)}
              </SubTitle>
            ) : (
              <SubTitle>{event.organizer}</SubTitle>
            )}
          </>
        </div>
        {event.own && !first && (
          <div style={{ marginTop: '0.2em' }}>
            <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
          </div>
        )}
      </Summary>
    </StatusWrapper>
  );
}

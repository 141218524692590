import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getFormatedTime } from '../utils';

interface ClockContext {
  formatedTime: string;
  clientAuthPollInterval: number;
}

const ClockContext = createContext<ClockContext>({
  formatedTime: '',
  clientAuthPollInterval: 0,
});
type Props = {
  children: ReactNode;
  unsafeClientAuthPollInterval: number;
};
export default function ClockProvider({
  children,
  unsafeClientAuthPollInterval,
}: Props) {
  // ensure poll interval is a multiple of 1000 and positive
  const clientAuthPollInterval =
    Math.floor(Math.abs(unsafeClientAuthPollInterval) / 1000) * 1000;
  const [formatedTime, setFormatedTime] = useState<string>(
    getFormatedTime(new Date()),
  );
  // init clock
  useEffect(() => {
    const timer = setInterval(() => {
      const newFormatedTime = getFormatedTime(new Date());
      if (newFormatedTime !== formatedTime) {
        setFormatedTime(newFormatedTime);
      }
    }, 1000);
    return () => clearInterval(timer);
    // disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClockContext.Provider
      value={{
        formatedTime,
        clientAuthPollInterval,
      }}
    >
      {children}
    </ClockContext.Provider>
  );
}

export function useClock() {
  return useContext(ClockContext);
}

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import useEvent from './useEvent';

const safeParser = (parseString: string) => {
  try {
    return JSON.parse(parseString);
  } catch (e) {
    return undefined;
  }
};

function useLocalStorage<T>(
  localStorageKey: string,
  defaultValue?: undefined,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>];
function useLocalStorage<T>(
  localStorageKey: string,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>];
function useLocalStorage<T>(localStorageKey: string, defaultValue: any) {
  const [value, setValue] = useState<T | undefined>(() => {
    const data = window.localStorage.getItem(localStorageKey);

    if (data) {
      return safeParser(data) as T;
    }
    return defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [localStorageKey, value]);

  useEvent('storage', () => {
    const data = window.localStorage.getItem(localStorageKey);
    if (data !== JSON.stringify(value)) {
      setValue(() => {
        if (data) {
          return safeParser(data) as T;
        }
        return defaultValue;
      });
    }
  });

  return [value, setValue] as const;
}

export default useLocalStorage;

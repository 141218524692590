import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Event from '../../models/Event';
import { useRooms } from '../../provider/RoomsProvider';
import { color } from '../../styles';

import { Button } from './Button';

const OuterWrapper = styled.div`
  overflow: hidden;
  margin-top: -0.15em;
`;

const Wrapper = styled.div<{ active: boolean }>`
  display: flex;
  width: 100%;
  ${(p) => css`
    transition: ${p.active
      ? 'transform 300ms cubic-bezier(.48,.38,.42,.89)'
      : 'transform 200ms cubic-bezier(.48,.38,.42,.89)'};
    transform: ${p.active ? 'translateX(0%)' : 'translateX(-100%)'};
  `}
`;

const Options = styled.div`
  flex: 1;
  white-space: nowrap;
`;

const StyledButton = styled(Button)<{ passive: boolean }>`
  ${(p) => css`
    transition: ${p.passive
      ? 'transform 300ms cubic-bezier(.48,.38,.42,.89)'
      : 'transform 200ms cubic-bezier(.48,.38,.42,.89)'};
    background-color: ${p.passive ? '#1F1F1F' : '#fff'};
    color: ${p.passive ? color.white : color.darkGrey};
    transform: ${p.passive ? 'translateX(0%)' : 'translateX(100%)'};
  `}
`;

const Option = styled(Button)`
  color: #000;
  background-color: ${color.white};
  margin-right: 0.5em;
`;

type Props = {
  event: Event;
  quickbooking: boolean;
};

export default function BookButton({ event, quickbooking }: Props) {
  const timerRef = useRef<NodeJS.Timeout>();
  const { book } = useRooms();
  const [active, setActive] = useState(false);
  /**
   * @description Thats option state to active for 10 seconds
   */
  const handleClick = () => {
    if (!active) {
      timerRef.current = setTimeout(() => {
        setActive(false);
      }, 10000);
    } else {
      clearTimeout(timerRef.current);
    }
    setActive((prev) => !prev);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <OuterWrapper>
      <Wrapper active={active || quickbooking}>
        <Options>
          {event.slots.map((slot, index) => (
            <Option
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={() => {
                book(event.room, slot.start, slot.end);
              }}
            >
              {slot.minutes}
            </Option>
          ))}
        </Options>
        {!quickbooking && (
          <StyledButton passive={active} onClick={handleClick}>
            {active ? 'Cancel' : 'Book now'}
          </StyledButton>
        )}
      </Wrapper>
    </OuterWrapper>
  );
}

import { useEffect } from 'react';

export default function useEvent<T extends keyof WindowEventMap>(
  name: T,
  handler: (ev: WindowEventMap[T]) => void,
) {
  useEffect(() => {
    window.addEventListener(name, handler);
    return () => {
      window.removeEventListener(name, handler);
    };
  });
}

import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRooms } from '../provider/RoomsProvider';
import { color } from '../styles';

const Title = styled.h1<{ free?: boolean }>`
  font-size: 1.5em;
  border-radius: 8px;
  margin: 0;
  user-select: none;
  display: inline-block;
  color: ${color.white};
  width: 100%;
  margin-left: 1em;
  transition: color 200ms cubic-bezier(0.48, 0.38, 0.42, 0.89);
`;

const StatusIndicator = styled.div`
  min-width: 2em;
  min-height: 2em;
  border-radius: 100%;
  margin: 0 1.45em;
  transition: background-color 200ms cubic-bezier(0.48, 0.38, 0.42, 0.89);
`;

const Wrapper = styled.div<{ backgroundColor: boolean; free?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 4vw;
  width: 100vw;
  z-index: 2;
  pointer-events: none;
  background-color: ${(p) =>
    p.backgroundColor ? color.darkGrey : 'transparent'};
  ${(p) => css`
    ${Title} {
      color: ${p.free ? color.free : color.busy};
    }
    ${StatusIndicator} {
      background-color: ${p.free ? color.free : color.busy};
    }
  `}
`;

const Time = styled.h1<{ updated: boolean }>`
  float: right;
  font-size: 1.5em;
  margin: 1em 0 1em 4vw;
  color: ${(p) => (p.updated ? color.sand : color.white)};
  -webkit-font-smoothing: antialiased;
  pointer-events: all;
`;

type Props = {
  time: string;
  title?: string;
  free?: boolean;
  className?: string;
};

export default function Header({ free, title, time, className }: Props) {
  const buttonPressTimer = useRef<NodeJS.Timeout>();
  const { updateDefaultPosition } = useRooms();
  const [updated, setUpdated] = useState(false);
  /**
   * @description Sets timeout to set the the current view as the default location
   */
  const handlePress = () => {
    buttonPressTimer.current = setTimeout(() => {
      updateDefaultPosition();
      setUpdated(true);
      setTimeout(() => {
        setUpdated(false);
      }, 350);
    }, 3000);
  };

  /**
   * @description Clears default location request
   */
  const handleRelease = () => {
    clearTimeout(buttonPressTimer.current);
  };

  return (
    <Wrapper className={className} free={free} backgroundColor={!!title}>
      {title && <StatusIndicator />}
      {title && <Title>{title}</Title>}
      <Time
        onTouchStart={handlePress}
        onTouchEnd={handleRelease}
        onMouseDown={handlePress}
        onMouseUp={handleRelease}
        updated={updated}
      >
        {time}
      </Time>
    </Wrapper>
  );
}

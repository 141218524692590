import { toString } from 'qrcode';
import { color } from '../../styles';

export interface Config {
  ssid: string;
  password: string;
}

function mecardFormat(input: string): string {
  return input
    .replace(/\\/g, '\\\\')
    .replace(/"/g, '\\"')
    .replace(/;/g, '\\;')
    .replace(/,/g, '\\,')
    .replace(/:/g, '\\:');
}

function generateString(input: Config) {
  const ssid: string = mecardFormat(input.ssid);
  const password: string = mecardFormat(input.password);

  return `WIFI:T:WPA;S:${ssid};P:${password};`;
}

export async function generateWifiQRCode(input: Config) {
  const wifiString = generateString(input);
  const retVal: string = await toString(wifiString, {
    type: 'svg',
    margin: 0,
    color: { dark: color.background, light: color.white },
  });
  return retVal;
}

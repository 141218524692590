import { useEffect, useState } from 'react';

export function isOnline() {
  return typeof navigator !== 'undefined' &&
    typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;
}

export function useOnlineChange(cb: (online: boolean) => any) {
  useEffect(() => {
    const listener = () => {
      cb(isOnline());
    };
    window.addEventListener('online', listener);
    window.addEventListener('offline', listener);
    return () => {
      window.removeEventListener('online', listener);
      window.removeEventListener('offline', listener);
    };
  }, [cb]);
}

export function useOnlineState() {
  const [online, setOnline] = useState(isOnline());
  useOnlineChange(setOnline);
  return online;
}

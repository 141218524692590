import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '../components/common/Button';
import EventCard from '../components/EventCard';
import Header from '../components/Header';
import Modal from '../components/Modal';
import WifiQr from '../components/WifiQr';
import { useClock } from '../provider/ClockProvider';
import { useRooms } from '../provider/RoomsProvider';
import { color } from '../styles';

const StyledButton = styled(Button)`
  color: ${color.white};
  background-color: transparent;
  padding: 0.7em 0.8em;
  font-size: 2.6vmin;
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
  border: 2px solid ${color.white};
`;

const OtherRooms = styled(StyledButton)`
  position: absolute;
  bottom: 1.9em;
  right: 4vw;
`;

const ContentWrapper = styled.div`
  margin: 0 4vw;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 4em;
  svg {
    height: 1em;
    margin-left: 0.8em;
  }
`;

const Wifi = styled(StyledButton)`
  display: flex;
  align-items: center;
`;

export default function RoomDetailsPage() {
  const { formatedTime } = useClock();
  const { selectedRoom, setSelectedRoom } = useRooms();
  const [showModal, setShowModal] = useState(false);
  if (!selectedRoom) {
    return null;
  }

  const ttLength = selectedRoom.timetable.length - 1;

  return (
    <>
      <Header
        title={selectedRoom?.name ?? ''}
        time={formatedTime}
        free={selectedRoom.isFree}
      />

      <ContentWrapper>
        <Row>
          <Wifi
            onClick={() => {
              setShowModal(true);
            }}
          >
            Guest WiFi
            <svg
              viewBox="0 0 42 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.571 18.44c-.577 0-1.146-.22-1.588-.662-6.065-6.056-15.918-6.056-21.983 0a2.25 2.25 0 01-3.177 0 2.25 2.25 0 010-3.177c7.815-7.814 20.522-7.814 28.337 0a2.25 2.25 0 01-1.589 3.84z"
                fill="#fff"
              />
              <path
                d="M27.49 24.514c-.578 0-1.147-.22-1.589-.662a6.907 6.907 0 00-4.91-2.03 6.908 6.908 0 00-4.91 2.03 2.25 2.25 0 01-3.176 0 2.25 2.25 0 010-3.177 11.359 11.359 0 018.086-3.347c3.058 0 5.93 1.19 8.087 3.355a2.25 2.25 0 010 3.177 2.244 2.244 0 01-1.588.654zM20.991 31.769a2.998 2.998 0 100-5.997 2.998 2.998 0 000 5.997zM39.747 12.266c-.578 0-1.147-.221-1.589-.663-9.47-9.462-24.862-9.462-34.325 0a2.25 2.25 0 01-3.177 0 2.25 2.25 0 010-3.177C6.093 2.99 13.313 0 21 0c7.687 0 14.907 2.99 20.344 8.426a2.25 2.25 0 010 3.177 2.28 2.28 0 01-1.597.663z"
                fill="#fff"
              />
            </svg>
          </Wifi>
        </Row>
        {selectedRoom.timetable.slice(0, 3).map((ev, index) => (
          <EventCard
            first={index === 0}
            last={ttLength < 3 ? index === ttLength : index === 2}
            key={ev.id}
            event={ev}
          />
        ))}
      </ContentWrapper>
      <OtherRooms
        onClick={() => {
          setSelectedRoom(null);
        }}
      >
        Check other rooms
      </OtherRooms>

      <Modal
        visible={showModal}
        onClose={() => setShowModal(false)}
        timer={40000}
      >
        <WifiQr />
      </Modal>
    </>
  );
}

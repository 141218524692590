import styled from 'styled-components';
import { color } from '../../styles';

export const Button = styled.div`
  min-width: 1em;
  text-align: center;
  color: ${color.white};
  background: #555;
  padding: 0.7em 0.8em;
  font-size: 2.6vmin;
  display: inline-block;
  white-space: nowrap;
  border-radius: 8px;
  cursor: pointer;
`;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { InitQuery_floors } from '../generated/InitQuery';
import useInteraction from '../hooks/useInteraction';
import useOrientation, { Orientation } from '../hooks/useOrientation';
import { useRooms } from '../provider/RoomsProvider';
import { color } from '../styles';
import DragContainer from './DragContainer';
import { Map } from './Map';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ScrollView = styled.div`
  background: ${color.background};
  scroll-snap-type: y mandatory;
  overflow: scroll;
  animation: ${fadeIn} 350ms ease-in-out;
  transform: translateZ(0px);
  perspective: 1px;
  perspective-origin: 0%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Line = styled.div<{ last: boolean }>`
  border: 1px dashed ${color.white};
  width: 0px;
  height: calc(100vh / 5 * 2 - var(--bubble-size));
  margin: 0 auto;
  ${(p) =>
    p.last &&
    css`
      height: 50vh;
      border-color: ${color.mediumGrey};
    `}
`;

const FloorNumber = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: var(--bubble-size);
  height: var(--bubble-size);
  background: pink;
  transition: all 400ms;
  pointer-events: all;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${color.mediumGrey};
  opacity: 0.6;
  ${(p) =>
    p.active &&
    css`
      background-color: ${color.background};
      transform: scale(1.6);
      border: 1px solid ${color.mediumGrey};
      opacity: 1;
    `};

  span {
    font-size: 0.65em;
    font-weight: bold;
    color: ${color.white};
  }
`;

const FloorIndicator = styled.div`
  position: fixed;
  --bubble-size: 2em;
  top: 0;
  left: calc(var(--bubble-size) + 1em);
  pointer-events: none;
  z-index: 1;
  padding-top: calc(40vh + var(--bubble-size) - 5px);
  transform-origin: 0% 50vh;
  transform: translateZ(-1.5px) scale(2.5);
  &.no-animation {
    ${FloorNumber} {
      transition: none;
    }
  }
`;
type Props = {
  floors: InitQuery_floors[];
  baseFloor?: number;
};

export default function FloorList({ floors, baseFloor }: Props) {
  const indicator = useRef<HTMLDivElement>(null);
  const scrollView = useRef<HTMLDivElement>(null);
  const [actFloorIndex, setActFloorIndex] = useState(0);
  const { setSelectedRoom, rooms } = useRooms();

  const handleBubbleClick = (index: number, animated = true) => {
    if (scrollView.current) {
      const { height } = scrollView.current.getBoundingClientRect();
      scrollView.current.scrollTo({
        behavior: animated ? 'smooth' : 'auto',
        top: height * index,
      });
    }
  };

  useInteraction(
    useCallback(() => {
      const index = floors.findIndex(
        (floor) => floor.floorNumber === baseFloor,
      );
      handleBubbleClick(index);
    }, [floors, baseFloor]),
  );

  const orientation = useOrientation();

  useEffect(() => {
    if (indicator.current) {
      const index = floors.findIndex(
        (floor) => floor.floorNumber === baseFloor,
      );
      indicator.current.classList.add('no-animation');
      handleBubbleClick(index, false);
    }
  }, [floors, baseFloor]);

  const handleFloorClick = (targetId: string) => {
    if (targetId) {
      setSelectedRoom(targetId);
    }
  };

  return (
    <ScrollView
      ref={scrollView}
      onScroll={(e) => {
        const { scrollTop } = e.currentTarget;
        const { height } = e.currentTarget.getBoundingClientRect();
        setActFloorIndex(Math.round(scrollTop / height));
        setTimeout(() => {
          indicator.current?.classList.remove('no-animation');
        }, 0);
      }}
    >
      {floors.map((floor) => (
        <DragContainer key={floor.id} floorNumber={floor.floorNumber}>
          <Map
            rooms={rooms}
            onClick={(e) => handleFloorClick((e.target as HTMLElement).id)}
            dangerouslySetInnerHTML={{
              __html:
                orientation === Orientation.LANDSCAPE
                  ? floor.map.horizontal
                  : floor.map.portrait,
            }}
          />
        </DragContainer>
      ))}

      <FloorIndicator ref={indicator}>
        {floors.map((f, i) => (
          <div key={f.id}>
            <FloorNumber
              active={actFloorIndex === i}
              onClick={() => handleBubbleClick(i)}
            >
              <span>{f.floorNumber}th</span>
            </FloorNumber>
            <Line last={i === floors.length - 1} />
          </div>
        ))}
      </FloorIndicator>
    </ScrollView>
  );
}

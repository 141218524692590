import { useMutation, useQuery } from '@apollo/client';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CheckAuth } from '../../generated/CheckAuth';
import { InitQuery, InitQuery_floors } from '../../generated/InitQuery';
import {
  UpdateDeviceLocation,
  UpdateDeviceLocationVariables,
} from '../../generated/UpdateDeviceLocation';
import useLocalStorage from '../../hooks/useLocalStorage';
import Event from '../../models/Event';
import Room from '../../models/Room';
import { CHECK_AUTH } from '../../queries/checkAuth';
import { INIT_QUERY, UPDATE_DEVICE_LOCATION } from './queries';
import useInteraction from '../../hooks/useInteraction';
import useRoomData from './useRoomData';
import useSockets from './useSockets';
import { getRoom, prepareFloor, useBookRoom, useCancelRoom } from './utils';

type Position = {
  x: number;
  y: number;
  floorNumber: number;
};

interface RoomsContextType {
  floors: InitQuery_floors[];
  primaryId: string | null;
  deviceId: string | null;
  defaultPosition: string | null;
  selectedRoom?: Room | null;
  rooms: Room[];
  setSelectedRoom: (id: string | null) => void;
  book: (room: Room, start: string, end: string) => void;
  cancel: (event: Event) => void;
  setDevicePosition: (position?: Position) => void;
  devicePosition?: { x: number; y: number; floorNumber: number };
  updateDefaultPosition: () => void;
}

const NotImplemented = () => {
  throw Error('not implemented');
};

const RoomsContext = createContext<RoomsContextType>({
  floors: [],
  primaryId: null,
  deviceId: null,
  defaultPosition: null,
  setSelectedRoom: NotImplemented,
  book: NotImplemented,
  cancel: NotImplemented,
  rooms: [],
  setDevicePosition: NotImplemented,
  updateDefaultPosition: NotImplemented,
});

type Props = { children: ReactNode };

export default function RoomsProvider({ children }: Props) {
  const bookRoom = useBookRoom();
  const cancelRoom = useCancelRoom();
  const [update, setUpdate] = useState(0);
  const [floors, setFloors] = useState<InitQuery_floors[]>([]);
  const [primaryId, setPrimaryId] = useState<string | null>(null);
  const [deviceId, setDeviceId] = useState<string | null>(null);
  const [defaultPosition, setDefaultPosition] = useState<string | null>(null);
  const { rooms, fetchRooms } = useRoomData(primaryId);
  const authResponse = useQuery<CheckAuth>(CHECK_AUTH);
  const authorized = authResponse.data?.authStatus?.status === 'true';
  const { data: initResponse, refetch: fetchInitialData } = useQuery<InitQuery>(
    INIT_QUERY,
    { skip: !authorized },
  );

  const [devicePosition, setDevicePosition] = useLocalStorage<Position>(
    'currentPosition',
  );
  const [updateDeviceLocation] = useMutation<
    UpdateDeviceLocation,
    UpdateDeviceLocationVariables
  >(UPDATE_DEVICE_LOCATION);

  const [selectedRoomId, setSelectedRoomId] = useState<string | null>();
  const selectedRoom = useMemo(() => {
    return getRoom(rooms, selectedRoomId!);
  }, [rooms, selectedRoomId]);

  useInteraction(
    useCallback(() => setSelectedRoomId(defaultPosition), [defaultPosition]),
  );

  useSockets(
    fetchRooms,
    fetchInitialData,
    initResponse?.publicSettings?.name,
    deviceId!,
  );

  useEffect(() => {
    if (initResponse) {
      setFloors(
        (initResponse.floors || [])
          .map(prepareFloor)
          .reverse()
          .filter(Boolean) as InitQuery_floors[],
      );

      setPrimaryId(initResponse.primaryId);
      setDeviceId(initResponse.deviceId);
      setDefaultPosition(initResponse.defaultPosition);
      if (initResponse.defaultPosition) {
        setSelectedRoomId(initResponse.defaultPosition);
      }
    }
  }, [initResponse]);

  const book = (room: Room, start: string, end: string) => {
    room.book(start, end);
    bookRoom({ variables: { start, end, attendees: room.id, summary: 'Meeting' } });
    // Set update in order to trigger a re-render
    setUpdate(Date.now());
  };

  const cancel = (event: Event) => {
    cancelRoom({ variables: { eventId: event.id } });
    event.cancel();
    // Set update in order to trigger a re-render
    setUpdate(Date.now());
  };

  const updateDefaultPosition = useCallback(() => {
    updateDeviceLocation({
      variables: { room: selectedRoom?.id || undefined },
    });
    setDefaultPosition(selectedRoomId || null);
  }, [updateDeviceLocation, selectedRoom, selectedRoomId]);

  return (
    <RoomsContext.Provider
      value={{
        floors,
        defaultPosition,
        deviceId,
        primaryId,
        selectedRoom,
        setSelectedRoom: setSelectedRoomId,
        book,
        cancel,
        rooms,
        devicePosition,
        setDevicePosition,
        updateDefaultPosition,
      }}
    >
      {children}
    </RoomsContext.Provider>
  );
}

export function useRooms() {
  return useContext(RoomsContext);
}

import React from 'react';
import styled from 'styled-components';

import Loader from '../components/common/Loader';
import { GlobalStyle } from '../Root';
import { color } from '../styles';

const Container = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: ${color.white};
  transform: translateY(-50%);
`;
export default function OfflinePage() {
  return (
    <Container>
      <GlobalStyle />
      <h2>No Connection</h2>
      <Loader />
    </Container>
  );
}

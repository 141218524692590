import React from 'react';
import styled, { keyframes } from 'styled-components';

const len = 242.776657104492;

const anim = keyframes`
  12.5% {
    stroke-dasharray: ${len * 0.14}px, ${len}px;
    stroke-dashoffset: -${len * 0.11}px;
  }
  43.75% {
    stroke-dasharray: ${len * 0.35}px, ${len}px;
    stroke-dashoffset: -${len * 0.35}px;
  }
  100% {
    stroke-dasharray: ${len * 0.01}px, ${len}px;
    stroke-dashoffset: -${len * 0.99}px;
  }
`;

const Container = styled.svg`
  display: block;
  margin: auto;
  height: 6em;
  & #outline {
    stroke-dashoffset: 0;
    stroke-dasharray: ${len * 0.01}px, ${len}px;
    animation: ${anim} 1.6s linear infinite;
  }
`;

export default function Loader() {
  return (
    <Container viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet">
      <path
        stroke="#ededed"
        id="outline"
        fill="none"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
      />
      <path
        opacity="0.05"
        fill="none"
        stroke="#ededed"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
      />
    </Container>
  );
}

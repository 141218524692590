import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { WifiQuery } from '../../generated/WifiQuery';
import { color } from '../../styles';
import { generateWifiQRCode } from './qr-code-generator';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Headline = styled.div`
  font-size: 1.9em;
  font-weight: bold;
  color: ${color.white};
  margin: 0.5em 0 0.75em;
`;

const QrCode = styled.div`
  width: 50%;
`;

const Text = styled.div`
  color: ${color.white};
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const WIFI_QUERY = gql`
  query WifiQuery {
    wifiSettings {
      SSID
      password
    }
  }
`;

export default function WifiQr() {
  const { loading, data, error } = useQuery<WifiQuery>(WIFI_QUERY);
  const [qrCode, setQrCode] = useState<string>();

  useEffect(() => {
    if (data?.wifiSettings?.password && data.wifiSettings.SSID) {
      generateWifiQRCode({
        ssid: data?.wifiSettings?.SSID,
        password: data?.wifiSettings?.password,
      }).then(setQrCode);
    }
  }, [data]);

  if (error || loading) return null;
  return (
    <Container>
      <Headline>Scan to connect</Headline>
      {qrCode && <QrCode dangerouslySetInnerHTML={{ __html: qrCode }} />}
      <Headline>or...</Headline>
      <Text>
        Wifi-Name: <b>{data?.wifiSettings?.SSID}</b>
      </Text>
      <Text>
        Password: <b>{data?.wifiSettings?.password}</b>
      </Text>
    </Container>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { ReactNode } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Draggable from 'react-draggable';
import { color } from '../styles';
import { Button } from './common/Button';
import { useRooms } from '../provider/RoomsProvider';

const Container = styled.div`
  box-sizing: border-box;
  height: 100vh;
  padding: 4vw;
  padding-left: calc(4vw + 1.8em);
  position: relative;
  scroll-snap-align: start;
`;

const pulse = keyframes`
 0% {
		box-shadow: 0 0 0 0 rgba(205, 163, 95, 0.7);
	}

	70% {
		box-shadow: 0 0 0 1.5vmin rgba(205, 163, 95, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(205, 163, 95, 0);
	}
`;

const DragItem = styled.div<{ drag: boolean }>`
  width: 2.5vmin;
  height: 2.5vmin;
  border: 3px solid ${color.sand};
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  ${(p) =>
    !p.drag &&
    css`
      animation: ${pulse} 1.5s infinite;
    `}
`;

const SubmitNewPosition = styled(Button)`
  position: absolute;
  bottom: 170%;
  left: -110%;
  background: ${color.sand};
  color: ${color.white};
`;

type Props = {
  children: ReactNode;
  floorNumber: number;
};

export default function DragContainer({ children, floorNumber }: Props) {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { devicePosition, setDevicePosition } = useRooms();
  const [change, setChange] = useState(false);
  const buttonPressTimer = useRef<NodeJS.Timeout>();
  const [defaultPosition, setDefaultPosition] = useState<{
    x: number;
    y: number;
  }>();

  const handlePress = () => {
    buttonPressTimer.current = setTimeout(() => {
      setChange(true);
    }, 3000);
  };

  const handleRelease = () => {
    clearTimeout(buttonPressTimer.current);
  };

  const handleDragEnd = (x: number, y: number) => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const xPercentage = 100 / (width / x);
    const yPercentage = 100 / (height / y);

    setDevicePosition({ x: xPercentage, y: yPercentage, floorNumber });
  };

  useEffect(() => {
    const resize = () => {
      setDefaultPosition(
        devicePosition
          ? {
              x: (devicePosition?.x * window.innerWidth) / 100,
              y: (devicePosition.y * window.innerHeight) / 100,
            }
          : { x: 100, y: 100 },
      );
    };
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [devicePosition]);

  return (
    <Container
      onTouchStart={handlePress}
      onTouchEnd={handleRelease}
      onMouseDown={handlePress}
      onMouseUp={handleRelease}
    >
      {(floorNumber === devicePosition?.floorNumber || change) && (
        <Draggable
          disabled={!change}
          defaultPosition={defaultPosition}
          position={defaultPosition}
          nodeRef={nodeRef}
          onStop={(_, { x, y }) => handleDragEnd(x, y)}
        >
          <DragItem ref={nodeRef} drag={change}>
            {change && (
              <SubmitNewPosition onPointerDown={() => setChange(false)}>
                done
              </SubmitNewPosition>
            )}
          </DragItem>
        </Draggable>
      )}
      {children}
    </Container>
  );
}

import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { color } from '../styles';

const Container = styled.div<{ visible: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 8px;
  z-index: 99;
  opacity: ${(p) => (p.visible ? 1 : 0)};
  transition: opacity 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(48, 48, 48, 0.8);
  pointer-events: ${(p) => (p.visible ? 'all' : 'none')};
`;

const Content = styled.div`
  border-radius: 8px;
  background: ${color.background};
  padding: 3vw;
`;

const CloseButton = styled.svg`
  display: block;
  height: 2vw;
  width: 2vw;
  margin-left: auto;
  color: ${color.white};
`;

type Props = {
  children: ReactNode;
  visible: boolean;
  onClose: () => void;
  timer?: number;
};

export default function Modal({ children, onClose, visible, timer }: Props) {
  useEffect(() => {
    if (visible && timer) {
      setTimeout(() => {
        onClose();
      }, timer);
    }
  }, [visible, timer, onClose]);

  return (
    <Container visible={visible}>
      <Content>
        <CloseButton
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onClose}
        >
          <path
            stroke="currentColor"
            strokeWidth="10"
            d="M3.464 96.465L96.465 3.464M3.536 3.464l92.999 93.001"
          />
        </CloseButton>
        {children}
      </Content>
    </Container>
  );
}

import React from 'react';
import styled from 'styled-components';

import FloorList from '../components/FloorList';
import Header from '../components/Header';
import { useClock } from '../provider/ClockProvider';
import { useRooms } from '../provider/RoomsProvider';

const StyledHeader = styled(Header)`
  position: absolute;
`;

export default function RoomOverviewPage() {
  const { formatedTime } = useClock();
  const { floors } = useRooms();
  const { devicePosition } = useRooms();
  return (
    <>
      <StyledHeader time={formatedTime} />
      <FloorList floors={floors} baseFloor={devicePosition?.floorNumber} />
    </>
  );
}
